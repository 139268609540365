import axios from "axios";
import { FETCH_USER, CHECK_TOKEN, LOGOUT } from "./types";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import { toast } from "react-toastify";
const keys = require("../config/keys");

export const fetchUser = (objUserDetails) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/login",
    data: {
      UserName: objUserDetails.username,
      Password: objUserDetails.password,
    },
  })
    .then((res) => {
      let auth = {};
      // console.log("res.data.userDetails.length",res.status)
      if (res.data.userDetails) {
        // console.log("res.data.userDetails.length",res.data.userDetails)
        if (res.data.userDetails.IsActive) {
          try {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.userDetails.Id);
            localStorage.setItem("UserName", res.data.userDetails.UserName);
            localStorage.setItem("IsLoggedIn", true);
            localStorage.setItem(
              "userdetails",
              JSON.stringify(res.data.userDetails)
            );
            setAuthorizationToken(res.data.token);

            auth.userId = res.data.userDetails.Id;
            auth.showLoader = false;
            auth.logOut = false;
            auth.redirectToLogin = false;
            auth.redirectToApplication = true;
            auth.message = "Welcome user";
            auth.userdetail = res.data.userDetails;
            dispatch({ type: FETCH_USER, payload: auth });
            toast.success("Welcome User", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              Transition: "slide",
            });
            //createHistory.push('/applicationform');
          } catch (exc) {
            console.log("exception", exc);
          }
        } else if (!res.data.userDetails.IsActive) {
          auth.showLoader = false;
          auth.message = "Access Denied";
          dispatch({ type: FETCH_USER, payload: auth });
        }
      } else {
        auth.showLoader = false;
        auth.messagetype = "error";
        auth.message = "Invalid username or password";
        dispatch({ type: FETCH_USER, payload: auth });
        toast.error(
          <div>
            <h5>Authentication1</h5>
            <p>Invalid username or password</p>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            Transition: "slide",
          }
        );
        // notification("error", "Invalid", "Check your Username & Password");
      }
    })
    .catch((e) => {
      console.log("catch error", e, JSON.stringify(e));
    });
};

export const checkUserToken = (objTokenDetails) => async (dispatch) => {
  // console.log("chktoken")
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/chkToken",
    data: { token: objTokenDetails },
  })
    .then((res) => {
      if (!res.data.error) {
        setAuthorizationToken(res.data.data.token);
        const auth = {};
        auth.userId = res.data.data.Id;
        auth.userType = res.data.data.UserType;
        auth.warehouseId = res.data.data.WarehouseId;
        auth.username = res.data.data.UserName;
        auth.showLoader = false;
        auth.logOut = false;
        auth.redirectToLogin = false;
        auth.redirectToApplication = true;
        dispatch({ type: CHECK_TOKEN, payload: auth });
      } else {
        logOutCurrentUser(dispatch);
      }
    })
    .catch((e) => {
      logOutCurrentUser(dispatch);
      //console.log("catch error", JSON.stringify(e));
    });
};

export const logout = () => async (dispatch) => {
  // console.log("logout");
  window.sessionStorage.clear();
  window.localStorage.clear();
  logOutCurrentUser(dispatch);
};

const logOutCurrentUser = function (dispatch) {
  delete axios.defaults.headers.common["token"];
  const res = {};
  res.redirectToLogin = true;
  res.logOut = true;
  dispatch({ type: LOGOUT, payload: res });
};