import { GET_ALL_MEMBERSHIP_DETAILS } from "../actions/types";
const initialState = [];

export const get_ALL_MEMBERSHIP_details = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_MEMBERSHIP_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
