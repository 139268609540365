import {
  GET_ALL_SERVICES,
  GET_FILTER_TICKETS
} from "../actions/types";

const initialState = [];

export const get_All_Services = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SERVICES:
      return action.payload;
    default:
      return state;
  }
};

export const getFilterTickets = function (state = initialState, action) {
  switch (action.type) {
    case GET_FILTER_TICKETS:
      return action.payload;
    default:
      return state;
  }
};