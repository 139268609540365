export const FETCH_USER = "fetch_user";
export const CHECK_TOKEN = "check_token";
export const LOGOUT = "logout";
export const GET_ALL_MEMBERS = "get_all_members";
export const GET_ALL_NEW_MEMBERS = "get_all_members";
export const GET_ALL_SERVICES = "get_all_services";
export const GET_FILTER_TICKETS = "get_filter_tickets";
export const UPDATE_DATA = "update_data";
export const GET_ALL_MEMBERS_details = "get_all_members_details";
export const GET_ALL_MEMBERSHIP_DETAILS = "get_all_membership_details";

export const GET_DOWNLOAD = "get_download";
