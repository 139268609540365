import { combineReducers } from "redux";
import auth from "./authReducer";
import {
  get_All_Members,
  get_New_Members,
  get_download,
  get_All_Member_Details,
} from "../reducers/members";
import { get_ALL_MEMBERSHIP_details } from "../reducers/membership";
import { get_All_Services, getFilterTickets } from "../reducers/Services";
import { changeState } from "../store";

export default combineReducers({
  auth,
  get_All_Members,
  get_New_Members,
  get_All_Services,
  getFilterTickets,
  get_download,
  changeState,
  get_All_Member_Details,
  get_ALL_MEMBERSHIP_details,
});
