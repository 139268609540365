import { FETCH_USER,CHECK_TOKEN,LOGOUT } from "../actions/types";

const initialState = {
  isLoggedIn: true
};

const  auth = (state = initialState, action) => {
  switch (action.type) {
    
    case FETCH_USER:
      return { ...state, ...action.payload };
    case CHECK_TOKEN:
      return { ...state, ...action.payload };
    case LOGOUT:
      return action.payload || false;
    default:
      return state;//{ ...state, ...action.payload };
  }
}

export default auth;