import axios from "axios";

const setAuthorizationToken =(token) => {
  // token
  //   ? (axios.defaults.headers.common["token"] = token)
  //   : delete axios.defaults.headers.common["token"];
  // console.log("SetAuthorization", token)
  if (token) {
    axios.defaults.headers.common["token"] = token;
    //console.log(jwt_decode(token));
  } else {
    delete axios.defaults.headers.common["token"];
  }
};


export default setAuthorizationToken;