import { GET_ALL_MEMBERS, GET_ALL_NEW_MEMBERS, GET_DOWNLOAD,GET_ALL_MEMBERS_details } from "../actions/types";

const initialState = [];

export const get_All_Members = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MEMBERS:
      return action.payload;
    default:
      return state;
  }
};

export const get_New_Members = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_NEW_MEMBERS:
      return action.payload;
    default:
      return state;
  }
};

export const get_download = function (state = initialState, action) {
  switch (action.type) {
    case GET_DOWNLOAD:
      return action.payload;
    default:
      return state;
  }
};

export const get_All_Member_Details = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MEMBERS_details:
      return action.payload;
    default:
      return state;
  }
};

