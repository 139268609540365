export const logoNegative = [
  `<g transform="translate(0.000000,627.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 3825 l0 -2445 3400 0 3400 0 0 2445 0 2445 -3400 0 -3400 0 0
-2445z m3296 1875 c38 -24 74 -92 74 -141 0 -49 -38 -119 -80 -147 -28 -19
-44 -23 -83 -19 -60 5 -95 30 -124 87 -28 55 -29 101 -3 156 43 87 134 114
216 64z m2934 -660 c3 -371 9 -683 14 -692 5 -12 20 -18 43 -18 27 0 43 -8 69
-34 34 -34 35 -37 32 -103 l-3 -68 -175 0 -174 0 -15 40 c-8 22 -16 42 -17 44
-2 2 -19 -10 -39 -28 -76 -66 -201 -108 -293 -97 -199 23 -305 195 -319 516
-12 290 42 511 154 627 106 108 289 132 416 53 l47 -29 0 129 0 128 -37 7
c-49 8 -69 24 -88 68 -15 37 -20 118 -8 131 4 3 93 5 197 4 l191 -3 5 -675z
m-5310 545 c0 -100 -43 -155 -120 -155 l-32 0 5 -457 c2 -310 8 -470 16 -494
19 -59 53 -102 101 -127 39 -20 56 -23 126 -20 105 5 142 27 181 106 l28 57 3
466 3 466 -42 6 c-72 9 -115 75 -107 165 l3 37 283 3 c321 3 299 9 287 -84 -9
-72 -43 -113 -102 -121 l-41 -5 -5 -457 c-5 -498 -9 -534 -65 -656 -52 -109
-120 -168 -241 -205 -124 -39 -316 -34 -438 12 -78 29 -162 110 -201 195 -55
120 -62 195 -62 679 l0 434 -30 0 c-77 0 -120 55 -120 155 l0 55 285 0 285 0
0 -55z m3068 -122 l2 -173 129 0 c152 0 157 -3 147 -82 -9 -65 -28 -101 -61
-116 -14 -7 -68 -12 -120 -12 l-95 0 0 -346 c0 -342 3 -376 34 -396 6 -4 47
-8 92 -8 94 0 106 -8 130 -86 25 -84 20 -102 -31 -125 -25 -11 -85 -25 -134
-31 -206 -25 -311 45 -341 228 -6 32 -10 218 -10 412 l0 352 -53 0 c-87 0
-127 47 -127 149 0 59 4 61 106 61 l74 0 0 91 c0 112 13 160 56 201 42 41 86
57 149 55 l50 -2 3 -172z m-1469 -162 c51 -26 97 -83 118 -146 14 -42 18 -109
21 -437 l3 -388 25 0 c36 0 78 -25 92 -54 19 -42 26 -124 12 -141 -19 -23
-375 -22 -384 1 -3 9 -6 202 -6 430 0 450 -1 460 -55 502 -51 40 -140 21 -218
-48 l-37 -32 -2 -431 -3 -432 -170 -3 c-219 -4 -215 -5 -215 59 0 93 41 149
108 149 l34 0 -5 343 c-2 188 -9 356 -14 372 -5 18 -17 31 -29 33 -70 11 -97
42 -108 125 -11 86 -8 87 167 87 107 0 158 -4 170 -12 9 -7 22 -36 29 -64 l12
-51 63 53 c34 29 77 61 95 70 88 46 222 53 297 15z m2435 2 c106 -38 201 -160
240 -308 23 -87 43 -322 29 -343 -9 -15 -41 -17 -313 -20 l-302 -2 6 -58 c11
-89 35 -151 75 -191 62 -62 136 -73 291 -46 119 21 146 19 175 -9 33 -34 44
-109 19 -138 -73 -82 -365 -131 -504 -84 -147 50 -246 167 -289 341 -33 132
-31 361 4 497 51 195 187 345 345 378 60 12 163 4 224 -17z m-1606 -495 l2
-478 41 0 c52 0 93 -22 107 -57 18 -48 24 -110 12 -132 -10 -20 -18 -21 -289
-21 -319 0 -308 -3 -297 80 13 96 46 130 126 130 l40 0 0 375 0 375 -40 0
c-53 0 -95 22 -108 57 -17 46 -23 112 -12 134 10 19 20 19 213 17 l202 -3 3
-477z m-261 -1060 c5 -7 45 -251 88 -542 44 -290 80 -524 82 -520 1 5 50 243
108 529 79 394 109 523 122 533 10 7 51 12 110 12 78 0 94 -3 101 -17 5 -10
54 -249 108 -530 54 -282 101 -509 105 -505 3 4 43 238 87 520 45 282 84 517
87 523 7 10 313 13 339 3 21 -8 22 -101 1 -150 -15 -35 -61 -64 -105 -64 -11
0 -20 -4 -20 -9 0 -8 -253 -1259 -265 -1308 -5 -22 -9 -23 -140 -23 -73 0
-136 4 -139 8 -2 4 -41 224 -85 487 -45 264 -84 491 -87 505 -5 20 -119 -514
-214 -997 0 -2 -61 -3 -135 -3 -131 0 -135 1 -140 23 -6 27 -235 1303 -235
1311 0 3 -9 6 -20 6 -44 0 -90 29 -105 64 -15 36 -21 137 -8 149 3 4 84 7 178
7 126 0 175 -3 182 -12z m1894 -377 c69 -27 133 -90 161 -161 21 -51 22 -78
29 -405 4 -192 10 -362 14 -376 5 -20 16 -28 47 -35 59 -13 81 -47 82 -126 l1
-63 -165 0 -164 0 -14 48 -14 48 -52 -49 c-77 -73 -106 -85 -214 -90 -88 -4
-96 -3 -158 28 -104 51 -157 139 -170 281 -14 142 35 269 131 341 79 60 220
98 363 98 l62 0 0 80 c0 104 -17 145 -67 166 -38 17 -60 16 -276 -2 -92 -8
-108 2 -126 73 -10 40 -10 48 6 65 36 40 149 84 258 102 63 10 213 -3 266 -23z
m627 -24 c6 -6 58 -194 117 -417 58 -222 107 -405 109 -407 1 -1 49 181 105
405 57 224 108 413 114 420 7 8 50 12 152 12 168 0 171 -2 161 -82 -11 -79
-29 -105 -83 -121 l-47 -14 -113 -384 c-146 -498 -248 -821 -279 -884 -34 -67
-96 -129 -158 -157 -98 -44 -275 -24 -385 43 -32 19 -33 22 -27 65 8 60 31
102 61 110 13 3 64 0 114 -6 49 -7 105 -10 125 -6 66 13 119 76 145 174 11 39
8 53 -43 211 -50 155 -133 429 -217 723 l-34 116 -37 6 c-52 8 -84 50 -93 121
-10 84 -8 85 160 85 102 0 146 -4 153 -13z"/>
<path d="M5720 5083 c-39 -20 -74 -67 -91 -122 -16 -51 -24 -392 -10 -466 16
-84 49 -146 91 -167 70 -36 184 -14 238 44 l22 25 -2 329 -3 329 -40 20 c-56
27 -159 32 -205 8z"/>
<path d="M4734 5100 c-50 -25 -87 -88 -103 -174 -6 -33 -11 -66 -11 -73 0 -10
36 -13 171 -13 l172 0 -6 78 c-6 88 -32 151 -72 178 -41 27 -104 28 -151 4z"/>
<path d="M4761 2714 c-100 -27 -151 -87 -151 -178 0 -60 19 -105 58 -138 61
-52 137 -45 210 18 l42 36 0 139 0 139 -52 -1 c-29 0 -77 -7 -107 -15z"/>
<path d="M9028 4611 c-61 -39 -92 -102 -86 -176 19 -223 339 -238 374 -17 26
162 -150 280 -288 193z"/>
<path d="M8547 4421 c-32 -33 -57 -65 -57 -71 0 -28 35 -68 239 -275 157 -158
224 -233 239 -267 21 -45 22 -62 22 -292 0 -306 -7 -291 139 -291 145 0 135
-21 141 298 4 230 7 266 24 296 10 19 119 138 242 265 261 269 256 260 171
344 -84 83 -76 87 -322 -158 -203 -202 -212 -210 -251 -210 -21 0 -51 8 -66
18 -16 9 -112 104 -214 210 -222 230 -217 227 -307 133z"/>
<path d="M8155 3316 c-122 -28 -283 -102 -476 -218 -142 -86 -140 -154 11
-357 425 -573 1167 -840 1862 -670 430 105 811 370 1064 740 76 111 87 154 54
207 -28 45 -80 66 -149 59 -64 -7 -118 -41 -234 -148 -43 -39 -80 -70 -82 -68
-2 2 25 42 61 89 75 99 89 138 70 185 -19 46 -52 65 -109 65 -69 0 -104 -19
-205 -109 -50 -45 -93 -81 -96 -81 -4 0 16 28 43 63 61 78 91 127 91 149 0 33
-52 79 -97 85 -56 7 -105 -17 -220 -112 -51 -41 -95 -75 -99 -75 -3 0 12 20
34 44 52 56 64 80 56 111 -7 29 -50 55 -89 55 -42 0 -95 -33 -236 -146 -73
-58 -150 -118 -172 -132 -46 -31 -53 -26 118 -74 172 -47 278 -93 337 -146
101 -91 125 -205 67 -320 -53 -107 -131 -154 -254 -153 -120 1 -243 37 -472
142 -84 38 -212 89 -284 114 -146 49 -159 57 -142 83 8 12 23 17 57 15 68 -2
161 -33 388 -129 112 -47 241 -97 288 -111 220 -64 348 -11 358 151 3 51 0 66
-20 96 -56 82 -178 145 -379 195 -134 33 -220 74 -371 178 -235 162 -398 225
-598 233 -77 2 -135 -1 -175 -10z"/>
<path d="M1644 1200 c-36 -14 -64 -59 -64 -102 0 -32 7 -47 34 -74 90 -90 218
13 164 131 -21 45 -82 66 -134 45z"/>
<path d="M3300 1060 l0 -150 -26 10 c-14 6 -51 10 -82 10 -111 0 -199 -65
-244 -180 -31 -79 -31 -241 0 -321 24 -61 73 -118 125 -146 46 -25 182 -25
221 0 20 14 31 16 39 8 6 -6 46 -11 88 -11 l78 0 -11 33 c-6 19 -12 198 -15
453 l-5 421 -69 12 c-109 18 -99 31 -99 -139z m-34 -286 l34 -16 0 -157 0
-157 -29 -17 c-36 -21 -63 -22 -100 -2 -38 19 -51 60 -51 164 0 105 13 154 47
181 31 24 54 25 99 4z"/>
<path d="M7165 1196 c-37 -17 -80 -62 -94 -99 -6 -16 -11 -60 -11 -98 l0 -69
-30 0 -30 0 0 -65 0 -65 30 0 30 0 0 -260 0 -260 85 0 85 0 0 260 0 260 59 0
60 0 20 55 c12 31 21 60 21 65 0 6 -35 10 -80 10 l-80 0 0 55 c0 85 44 115
122 81 13 -6 22 3 42 43 14 28 26 54 26 60 0 34 -192 55 -255 27z"/>
<path d="M10470 1061 l0 -150 -30 11 c-17 5 -62 8 -100 6 -83 -4 -137 -36
-187 -112 -109 -161 -71 -433 71 -521 44 -27 58 -30 131 -30 62 0 88 4 109 18
20 14 31 16 39 8 6 -6 46 -11 88 -11 l78 0 -11 33 c-7 20 -13 189 -15 453 l-5
421 -68 12 c-110 18 -100 31 -100 -138z m-19 -297 c17 -15 19 -33 19 -164 0
-129 -2 -149 -18 -163 -50 -45 -131 -24 -152 38 -15 45 -15 195 0 240 16 49
56 78 99 71 18 -3 42 -13 52 -22z"/>
<path d="M140 803 c0 -395 2 -405 66 -465 60 -56 117 -73 244 -73 126 0 171
13 239 71 69 58 71 67 71 467 l0 357 -90 0 -90 0 0 -321 c0 -360 -2 -374 -67
-404 -79 -38 -165 0 -183 81 -5 21 -10 175 -10 342 l0 302 -90 0 -90 0 0 -357z"/>
<path d="M3820 1147 c0 -8 45 -206 100 -442 55 -235 100 -429 100 -431 0 -2
47 -4 104 -4 99 0 104 1 109 23 16 62 97 492 98 515 1 15 6 2 11 -28 13 -72
86 -425 99 -472 l10 -38 99 0 c55 0 100 2 100 5 0 3 45 196 100 429 55 232
100 430 100 439 0 15 -13 17 -93 17 l-94 0 -43 -207 c-23 -114 -48 -241 -54
-283 -13 -81 -20 -82 -31 -5 -3 28 -26 148 -51 269 -24 120 -44 220 -44 222 0
2 -45 4 -100 4 -55 0 -100 -2 -100 -4 0 -3 -20 -96 -44 -208 -25 -112 -50
-242 -57 -290 l-13 -88 -22 128 c-12 70 -38 200 -58 290 -20 90 -36 165 -36
168 0 2 -43 4 -95 4 -72 0 -95 -3 -95 -13z"/>
<path d="M7700 721 l0 -441 211 0 c138 0 226 4 252 12 128 39 194 216 129 345
-23 44 -43 64 -91 88 l-34 17 32 20 c51 33 81 94 81 167 0 74 -16 115 -61 158
-63 59 -93 66 -316 71 l-203 4 0 -441z m366 246 c33 -38 33 -95 -1 -131 -22
-24 -30 -26 -105 -26 l-80 0 0 96 0 96 81 -4 c74 -3 83 -6 105 -31z m18 -348
c45 -53 28 -151 -31 -178 -13 -6 -55 -11 -93 -11 l-70 0 0 110 0 110 84 0 c82
0 85 -1 110 -31z"/>
<path d="M2013 1097 l-73 -20 0 -73 0 -74 -35 0 -35 0 0 -65 0 -65 35 0 35 0
0 -217 c0 -196 2 -221 19 -249 37 -60 157 -90 251 -62 50 15 56 28 41 86 l-11
39 -49 -5 c-77 -7 -81 4 -81 225 l0 183 53 0 53 0 19 53 c10 28 20 58 23 65 3
9 -16 12 -73 12 l-78 0 7 95 c7 110 15 105 -101 72z"/>
<path d="M963 919 l-62 -19 11 -33 c6 -17 13 -157 15 -309 l5 -278 84 0 84 0
0 224 0 225 26 20 c14 11 41 25 60 31 30 10 38 9 55 -6 18 -16 19 -32 19 -256
l0 -238 85 0 85 0 0 278 c0 260 -1 279 -20 310 -49 81 -185 94 -289 27 l-36
-22 -10 31 c-12 38 -30 41 -112 15z"/>
<path d="M1663 928 l-63 -10 0 -319 0 -319 85 0 85 0 0 330 0 330 -22 -1 c-13
-1 -51 -6 -85 -11z"/>
<path d="M2473 923 c-58 -20 -134 -105 -156 -173 -22 -67 -23 -217 -2 -288 21
-69 69 -129 135 -166 51 -29 62 -31 150 -31 88 0 101 3 163 33 38 19 71 40 74
48 3 8 -9 36 -27 64 l-32 49 -54 -31 c-60 -33 -131 -41 -172 -19 -30 16 -62
69 -62 104 l0 27 181 0 182 0 -6 99 c-11 203 -101 302 -271 300 -34 0 -80 -7
-103 -16z m149 -124 c21 -11 48 -66 48 -99 0 -19 -6 -20 -90 -20 -85 0 -90 1
-90 21 0 74 73 129 132 98z"/>
<path d="M5020 921 c-78 -26 -150 -63 -150 -78 0 -10 32 -69 65 -121 1 -1 32
14 69 33 47 24 81 35 111 35 55 0 75 -19 75 -71 l0 -39 -62 0 c-90 0 -173 -26
-218 -69 -80 -76 -80 -223 -1 -302 64 -64 196 -76 285 -26 l41 23 44 -33 c42
-33 44 -33 63 -16 11 10 34 35 50 56 l31 38 -21 22 c-38 40 -43 73 -40 261 3
199 -3 224 -59 264 -66 46 -184 55 -283 23z m170 -425 c0 -58 -3 -67 -26 -85
-58 -46 -124 -16 -124 55 0 56 46 89 128 93 21 1 22 -3 22 -63z"/>
<path d="M5510 928 c-41 -5 -76 -11 -78 -13 -2 -2 43 -145 100 -317 92 -276
107 -313 127 -316 l21 -3 -27 -50 c-30 -57 -61 -82 -120 -101 l-41 -12 29 -58
c25 -50 33 -58 58 -58 49 0 148 57 181 105 37 54 27 27 180 460 l128 360 -90
3 c-50 1 -92 1 -94 -1 -7 -7 -116 -363 -129 -422 -7 -33 -13 -51 -14 -39 -1
11 -30 120 -66 242 -36 123 -65 225 -65 227 0 6 2 6 -100 -7z"/>
<path d="M6558 927 c-71 -23 -123 -70 -159 -143 -33 -66 -34 -73 -34 -184 0
-111 1 -118 33 -183 39 -79 87 -119 171 -143 106 -32 238 5 297 83 57 74 69
118 69 248 0 142 -16 187 -90 260 -71 68 -187 93 -287 62z m121 -128 c53 -19
66 -56 66 -194 0 -96 -4 -127 -18 -157 -31 -61 -95 -70 -140 -20 -41 45 -46
249 -9 323 13 26 48 56 68 59 1 0 15 -5 33 -11z"/>
<path d="M8590 933 c-33 -7 -137 -50 -167 -70 l-23 -15 31 -57 c18 -31 35 -59
38 -63 3 -4 37 8 75 27 116 59 186 43 186 -41 l0 -31 -87 -5 c-175 -10 -252
-79 -253 -224 0 -131 76 -200 220 -198 76 0 122 15 149 48 12 14 15 13 33 -9
11 -14 32 -32 48 -40 28 -14 29 -14 75 40 l47 55 -31 35 -31 36 0 195 c0 228
-8 257 -83 296 -42 22 -168 34 -227 21z m138 -439 c-5 -76 -27 -104 -81 -104
-45 0 -67 25 -67 76 0 48 17 71 58 80 15 3 32 7 37 9 6 2 21 4 34 4 23 1 23
-1 19 -65z"/>
<path d="M9105 920 c-49 -14 -59 -20 -56 -36 2 -10 6 -151 8 -311 l4 -293 90
0 89 0 0 229 0 229 36 22 c27 17 45 21 75 17 l39 -5 23 76 c27 88 25 92 -44
92 -47 0 -107 -30 -136 -69 -11 -14 -12 -13 -13 7 0 34 -18 62 -38 61 -9 -1
-44 -9 -77 -19z"/>
<path d="M9620 925 c-63 -20 -124 -75 -157 -143 -23 -48 -28 -72 -31 -158 -7
-170 38 -269 148 -329 47 -25 66 -29 130 -30 101 0 157 22 214 86 62 69 81
129 80 259 0 87 -4 114 -22 155 -27 60 -82 119 -137 147 -51 27 -163 33 -225
13z m148 -138 c35 -28 46 -71 47 -182 0 -90 -4 -117 -20 -150 -25 -51 -61 -69
-104 -54 -54 19 -66 56 -66 201 0 113 2 130 21 155 37 50 83 61 122 30z"/>
<path d="M10948 930 c-48 -12 -163 -64 -172 -78 -7 -11 63 -135 73 -129 173
92 251 89 251 -9 l0 -31 -87 -5 c-176 -11 -253 -78 -253 -220 0 -83 28 -140
83 -172 80 -46 196 -45 263 1 l37 24 26 -24 c56 -52 63 -52 116 10 41 47 46
57 34 67 -46 34 -49 52 -49 250 0 169 -2 194 -20 231 -30 61 -84 88 -185 91
-44 2 -97 -1 -117 -6z m146 -437 c-7 -78 -26 -103 -79 -103 -52 0 -83 50 -65
105 13 38 57 60 128 64 22 1 22 -1 16 -66z"/>
</g>`,
];
