import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { checkUserToken } from "./actions/auth";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
//const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import("./components/login/Login"));
const Register = React.lazy(() => import("./components/register/Register"));

// const Registercopy2 = React.lazy(() =>
//   import("./components/register/Registercopy2")
// );
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));
const AddMembers = React.lazy(() =>
  import("./components/addMembers/AddMembers")
);
const BasicForms = React.lazy(() =>
  import("./components/dashboard/BasicForms")
);
const TicketManage = React.lazy(() =>
  import("./components/helpdesk/ticketsmanage")
);
const PrintReceipts = React.lazy(() =>
  import("./components/payment/PrintReceipts")
);
const PaymentReceipt = React.lazy(() =>
  import("./components/payment/paymentReceipt")
);
const Member = React.lazy(() => import("./components/members"));

class App extends Component {
  async componentWillMount() {
    if (localStorage.token !== undefined && localStorage.token !== "")
      await this.props.checkUserToken(localStorage.token);
  }
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/paymentreceipt" component={PaymentReceipt} />
            <Route exact path="/printreceipt" component={PrintReceipts} />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            {/* <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Registercopy2 {...props} />}
            /> */}

            <Route
              exact
              path="/addMember"
              name="AddMembers Page"
              render={(props) => <AddMembers {...props} />}
            />
            <Route
              exact
              path="/dashboard"
              name="Dashboard Page"
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              exact
              path="/members"
              name="Members Page"
              render={(props) => <Member {...props} />}
            />
            {/* <Route
              exact
              path="/basicforms"
              name="Basic Forms"
              render={(props) => <BasicForms {...props} />}
            /> */}
            <Route
              exact
              parth="/ticketmanage"
              name="Ticket Manage"
              render={(props) => <TicketManage {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { checkUserToken })(App);
